
import { defineComponent, reactive, toRefs } from 'vue';

import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useCookies } from 'vue3-cookies';

import axios from '@/utils/axios';

export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
  },
  setup() {
    const { cookies } = useCookies();
    const router = useRouter();

    let formState = reactive({
      username: '',
      name: '',
      password: '',
      password2: '',
    });

    const signState = reactive({
      isSignUp: false,
    });

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const submit = async () => {
      if (signState.isSignUp) {
        axios
          .post(`/users/`, {
            username: formState.username,
            name: formState.name,
            password: formState.password,
          })
          .then(() => {
            Modal.info({
              title: '회원가입 성공',
              content: '로그인 후에 이용해주세요.',
            });
            signState.isSignUp = false;
          })
          .catch((e) => {
            Modal.info({
              title: '회원가입 실패',
              content: e.response.data.message,
            });
          });
      } else {
        axios
          .post(`/auth/login/`, {
            username: formState.username,
            password: formState.password,
          })
          .then(({ data }) => {
            cookies.set('accessToken', data.accessToken, '1d');

            router.push({ name: 'ChangeProcess' });
          })
          .catch((e) => {
            Modal.info({
              title: '로그인 실패',
              content: e.response.data.message,
            });
          });
      }
    };

    return {
      ...toRefs(signState),
      formState,
      layout,
      submit,
    };
  },
});
